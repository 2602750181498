import { createContext, useMemo, useState, ReactNode } from 'react';

export type LoadingSpinner = {
  showSpinner: boolean;
  setShowSpinner: (showSpinner: boolean) => void;
  showMapLoader: boolean;
  setShowMapLoader: (showSpinner: boolean) => void;
  showMapLoaderInMediumView: boolean;
  setShowMapLoaderInMediumView: (showSpinner: boolean) => void;
};

export const LoadingContext = createContext<LoadingSpinner>({
  showSpinner: false,
  setShowSpinner(): void {},
  showMapLoader: false,
  setShowMapLoader(): void {},
  showMapLoaderInMediumView: false,
  setShowMapLoaderInMediumView(): void {}
});

type Props = {
  children: ReactNode;
};

export function LoadingProvider({ children }: Props) {
  const [showSpinner, setShowSpinner] = useState(false);

  // only being used for full width map on search as i move
  const [showMapLoader, setShowMapLoader] = useState(false);
  const [showMapLoaderInMediumView, setShowMapLoaderInMediumView] = useState(false);

  const value = useMemo(
    () => ({
      showSpinner,
      setShowSpinner,
      showMapLoader,
      setShowMapLoader,
      showMapLoaderInMediumView,
      setShowMapLoaderInMediumView
    }),
    [showMapLoader, showSpinner, showMapLoaderInMediumView]
  );
  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
}
